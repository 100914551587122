import React from 'react';

import { connect } from 'react-redux';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as actionTypes from '../../store/actions';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {

    constructor(props) {
    super(props);
    this.state = {
        authUser: null
    };
    }
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
          authUser => {
            if(authUser){
              authUser.getIdToken(false).then(idToken => {
                // Send token to your backend via HTTPS
                // ...
                console.log("auth user token: "+idToken);
                this.props.onIngredientsAdded(idToken);
              this.setState({ authUser });

                // this.state.propfun(idToken);
              }).catch(function(error) {
                // Handle error
                console.log("auth token error:"+ error);
              });
            }else{
              this.setState({ authUser: null });
            }
          },
      );

    }

    componentWillUnmount() {
    this.listener();
    }

    render() {
        return (
            <AuthUserContext.Provider value={this.state.authUser}>
              <Component {...this.props} />
            </AuthUserContext.Provider>
          );
    }

  }

  const mapStateToProps = state => {
    return {
      ings: state.ingredients
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken})
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(withFirebase(WithAuthentication));
};



export default withAuthentication;