import React from 'react';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import CustomNavBar from '../CustomNavBar';
import SignOut from '../SignOut';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import axios from '../../axios';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';

class RewardDetails extends React.Component{ 

  constructor(props) {
    super(props);
    this.state = {
      pointAmount: 0,
      emailInput: null,
      isProcessing: false,
      rewardId: 0
    };
  }

  gotSubmitInfo = (event) => {
    console.log("Submit");
    console.log("Submit:"+this.props.currFirebaseToken);
    event.preventDefault();
    let pointAmount = this.state.pointAmount;
    let emailInput = this.state.emailInput;
    if (!Number(pointAmount)) {
      alert("Please enter point amount");
    }else if(pointAmount <= 0){
      //event.stopPropagation();
      
      alert("Point amount must be greater than 0");

    }
    else if(emailInput == null){
      alert("Please enter an email address");
    }else{

      this.setState({
        ...this.state.pointAmount,
        ...this.state.emailInput,
        ...this.state.rewardId,
        isProcessing: true
      });
      console.log("Token:"+this.props.currFirebaseToken);
      console.log("RewardId:"+this.state.rewardId);
      console.log("email:"+this.state.emailInput);
      console.log("amount a:"+this.state.pointAmount);
      const condition = authUser => !!authUser;
      if(condition && this.props.currFirebaseToken != null){
        const formData = new FormData();
        formData.append('FirebaseToken', this.props.currFirebaseToken);
        formData.append('IntTag', this.state.rewardId);
        formData.append('IntTag2', this.state.pointAmount);
        formData.append('Message', this.state.emailInput);
        axios.post(
            "/ClaimOffer",
            formData
          )
          .then(res => {
                    console.log(res.data);
                    console.log("Made call");
                    
          })
          .catch(error => {
            console.log(error);
          });
      }else{
        console.log("No call made");
      }
    }
    
  }

  myChangeHandler = (event) => {
    console.log("Name:"+event.target.name);
    console.log("Value:"+event.target.value);
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val});
  }

  render (){ return(
      <AuthUserContext.Consumer>
      {authUser => (
        <div style={{paddingTop:  '10rem'}}>
           <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
           <h1>Reward Details</h1>
           { !this.state.isProcessing &&
            <Form onSubmit={this.gotSubmitInfo}>
              <Form.Group controlId="formPointAmount">
                <Form.Label>Enter the amount points you want to spend</Form.Label>
                <Form.Control required type="number" placeholder="Enter amount" min="1000" name='pointAmount' onChange={this.myChangeHandler}/>
                <Form.Text className="text-muted">
                  
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail" >
                <Form.Label>Input the email address you would like us to send your payment</Form.Label>
                <Form.Control required type="email" placeholder="Enter email" name='emailInput' onChange={this.myChangeHandler}/>
                <Form.Text className="text-muted">
                  
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          }
        </div>
        )}
        </AuthUserContext.Consumer>
      );
  }

  componentDidMount(){
    console.log("Reward Details mounted");
    const {firebaseToken, rewardId, currUser } = this.props.location.state;
    console.log("Reward id: "+ rewardId);
    this.setState({
      ...this.state.pointAmount,
      ...this.state.emailInput,
      ...this.state.isProcessing,
      rewardId: rewardId
    });
  }

}

const mapStateToProps = state => {
  return {
    currFirebaseToken: state.currFirebaseToken,
    currUser: state.currUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
      onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
  }
}

const condition = authUser => !!authUser;
export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(condition)(RewardDetails));