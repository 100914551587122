import React, { Component } from 'react';
import * as ROUTES from '../constants/routes';
import { withFirebase } from './Firebase';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import CustomNavBar from './CustomNavBar';
import { FacebookLoginButton } from "react-social-login-buttons";
import GoogleButton from 'react-google-button'

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
  };

  class SignInGoogleBase extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null };
    }
    onSubmit() {
      this.props.firebase
        .doSignInWithGoogle()
        .then(socialAuthUser => {
          this.setState({ error: null });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error });
        });
    };
    render() {
      const { error } = this.state;
      return (
        <form >
          <GoogleButton type="light"
           onClick={() => { this.onSubmit() }} className="h-100 w-100 googleButton d-flex align-items-center">
           {error && <p>{error.message}</p>}</GoogleButton>
          
          
        </form>
      );
    }
  }
  
  
  class SignInFacebookBase extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null };
    }
    onSubmit = event => {
      this.props.firebase
        .doSignInWithFacebook()
        .then(socialAuthUser => {
          // Create a user in your Firebase Realtime Database too
          return this.props.firebase
            .user(socialAuthUser.user.uid)
            .set({
              username: socialAuthUser.additionalUserInfo.profile.name,
              email: socialAuthUser.additionalUserInfo.profile.email,
              roles: {},
            });
        })
        .then(() => {
          this.setState({ error: null });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error });
        });
      event.preventDefault();
    };
  
    render() {
      const { error } = this.state;
      return (
        <form onSubmit={this.onSubmit}>
          <FacebookLoginButton type="submit" className="rounded align-items-center">
          <span>Continue with Facebook</span>
          </FacebookLoginButton>
          {/* <button type="submit">Sign In with Facebook</button> */}
          {error && <p>{error.message}</p>}
        </form>
      );
    }
  }

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const {  email, passwordOne } = this.state;
    // const { username, email, passwordOne } = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {

    const {
        username,
        email,
        passwordOne,
        passwordTwo,
        error,
      } = this.state;

      const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <div style={{paddingTop: this.props.showButton ? '13rem' : '10rem' }}>
        <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
        <div className="d-flex justify-content-center mt-3">
          <div className="signInSection ">
            <p className="text-center">Sign Up and Get a <b>$20 Bonus</b> Today!&#42;</p>
            <SignInGoogle  />
            <SignInFacebook />
            <p className="mt-2" style={{color:'gray', textAlign:'center'}}>or</p>
            <form  onSubmit={this.onSubmit}>
              <div className="d-flex flex-column" >
                <input
                className="border-dark rounded-sm mb-2" style={{borderWidth: '1px'}}
                name="username"
                value={username}
                onChange={this.onChange}
                type="text"
                placeholder="  Full Name"
                />
                <input
                className="border-dark rounded-sm mb-2" style={{borderWidth: '1px'}}
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="  Email Address"
                />
                <input
                className="border-dark rounded-sm mb-2" style={{borderWidth: '1px'}}
                  name="passwordOne"
                  value={passwordOne}
                  onChange={this.onChange}
                  type="password"
                  placeholder="  Password"
                />
                <input
                className="border-dark rounded-sm mb-2" style={{borderWidth: '1px'}}
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={this.onChange}
                  type="password"
                  placeholder="  Confirm Password"
                />
                <button disabled={isInvalid} className="joinButton font-weight-bold pb-1 pt-1"  type="submit">Join Now</button>
                {/* disabled={isInvalid} */}
                {error && <p>{error.message}</p>}
              </div>
            </form>
            <br/>
            <a href="/signin" style={{color:'rgb(85, 186, 153)'}}>Already Have an Account? Sign in.</a>
            <br/><br/>

            <h6 className="text-center small" style={{color:'gray'}}>
              By joining, I agree to the <u><a href="/home" className="text-reset">Terms and Conditions</a></u> and <u><a href="/home" className="text-reset">Privacy Policy</a></u>
            </h6>
            <h6 className="text-center small" style={{color:'gray'}}>&#42;Special Offer terms apply</h6>
            </div>
      </div>
      </div>
    );
  }


}
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
    withRouter,
    withFirebase,
  )(SignUpFormBase);

  const SignInGoogle = compose(
    withRouter,
    withFirebase,
  )(SignInGoogleBase);
  
  const SignInFacebook = compose(
    withRouter,
    withFirebase,
  )(SignInFacebookBase);

  export default SignUpPage;
  export { SignUpForm, SignUpLink , SignInGoogle, SignInFacebook};