import axios from "axios";

const instance = axios.create({
  //baseURL: "http://betfullyrest.chickenkiller.com:50764/api/mobileapp",
  baseURL: "https://betfully.sombiesgame.com/api/mobileapp",
  headers: { "Content-Type": "multipart/form-data" },
});

//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// instance.interceptors.request...

export default instance;
