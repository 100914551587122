import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


import './App.css';
import CardSection from './components/CardSection';
import axios from '../src/axios';
import * as cardTypes from './constants/cardTypes';
import * as appConstants from './constants/constants';

export default class GrabOffers extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: []
      };
      //this.handleOfferSelect = this.handleOfferSelect.bind(this);
    }
   
    componentDidMount() {
        
    }
  
    render() {
      console.log("loading tab: "+this.props.tabNumber);
      const { error, isLoaded, items } = this.state;
      //   return <MapOffers/>;
      // const condition = authUser => !!authUser;
      // if(condition){
      //   return <div>Please Register</div>;
      // }
      // else 
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (this.props.UserOffers == null) {
        return <div>Loading...</div>;
      } else {
        var typeList = [];
              
        typeList = appConstants.TAB_CASHBACK;
        console.log("filter: "+ typeList);
        switch(this.props.tabNumber){
          case 1:
            typeList = appConstants.TAB_CASHBACK;
            console.log("filter: "+ typeList);
            break;
          case 2:
            typeList = appConstants.TAB_OFFER;
            console.log("filter: "+ typeList);
            break;
          case 3:
            typeList = appConstants.TAB_FREE;
            console.log("filter: "+ typeList);
            break;
    
        }


        var filteredOffers = this.props.UserOffers.filter(number => typeList.includes(number.mOfferItem.OfferType));
        const listItems =  filteredOffers.map((number) => <CardSection FirebaseToken={this.props.FirebaseToken} key={number.Id} OfferId={number.Id} offerClicked={this.props.handleOfferSelect} cardType={cardTypes.OFFER} idCardImg="/assets/BetFully_v1-06.png" CardTitle={number.mOfferItem.Title} CardDesc={number.mOfferItem.Description}/>);
    
        return (<div  className="d-flex flex-column  align-items-center" >{listItems}<br /></div>);
      }    }

    // handleOfferSelect(offerId) {
    //   // send offerid to client server
    //   console.log("Offer selected: "+offerId);
    //   this.props.handleOfferSelect(offerId);
    // }
  }