export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const OFFER_DETAILS = '/offerdetails';
export const ACCOUNT_DETAILS = '/account/details';
export const ACCOUNT_FAV = '/account/favorite_sites';
export const ACCOUNT_TRANSACTIONS = '/account/transactions';
export const ACCOUNT_CASHOUT = '/account/cashout';
export const ACCOUNT_ACCOUNTS = '/account/bankaccounts';
export const BANKACCOUNT_DETAILS = '/account/bankaccountdetails';
export const ADMIN = '/admin';
export const REWARDS = '/reward';
export const REWARD_DETAILS = '/reward/details';
export const PASSWORD_FORGET = '/pw-forget';