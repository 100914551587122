import React from 'react';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import CustomNavBar from '../CustomNavBar';
import CardSection from '../CardSection';
import SignOut from '../SignOut';
import * as cardTypes from '../../constants/cardTypes';
import * as actionTypes from '../../store/actions';
import { connect } from 'react-redux';
import axios from '../../axios';

class Cashout extends React.Component{ 

  constructor(props) {
    super(props);
    this.state = {
      userRewards: null
    };
  }

  componentDidMount(){
    const {firebaseToken, currUser } = this.props.location.state;
    this.props.onUserRetrieved(currUser);
  
    console.log("Transactions mounted");
      const condition = authUser => !!authUser;
      if(condition && this.props.currFirebaseToken != null){
        console.log("TransactionHistory call made");
        this.grabDetails(this.props.currFirebaseToken);
      }else{
        console.log("TransactionHistory cannot make call");
      }
  }

  render (){ return(
    <AuthUserContext.Consumer>
    {authUser => (
      <div style={{paddingTop:  '10rem', backgroundColor: 'lightgray'} }>
         <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
         <h1>Cashout</h1>
         {this.state.userRewards != null &&
                this.state.userRewards.map(
                  (number) => 
                    <CardSection 
                      key={number.RedeemableRewardId} 
                      cardType={cardTypes.REDEEMED_REWARD} 
                      PointsValue={number.PointsValue}  
                      CashValue={number.CashValue}
                      DateClaimed={number.DateClaimed} 
                      />)
            }
      </div>
      )}
      </AuthUserContext.Consumer>
    );
  }

  grabDetails(firebaseToken) {
    
    const formData = new FormData();
    formData.append('FirebaseToken', firebaseToken);
    axios.post(
        "/GetUserRewardHistory",
        formData
        )
        .then(res => {
          if(res.data != null){
            console.log("Reward History Grabbed");
            console.log(res.data);
            this.setState({
              userRewards : res.data
            });
          }else{
            this.setState({
              userRewards : null
            });
            console.log("Failed to grab Reward History ");
          }
        })
        .catch(error => {
            console.log(error);
        });
  }

}

const mapStateToProps = state => {
  return {
    currFirebaseToken: state.currFirebaseToken,
    currUser: state.currUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
    onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
  }
}


const condition = authUser => !!authUser;
export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(condition)(Cashout));