import React from 'react';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import CustomNavBar from '../CustomNavBar';
import CardSection from '../CardSection';
import SignOut from '../SignOut';
import axios from '../../axios';
import { usePlaidLink } from 'react-plaid-link';

import * as actionTypes from '../../store/actions';
import { connect } from 'react-redux';
import * as cardTypes from '../../constants/cardTypes';
import * as plaidConst from '../../constants/plaidConstants';
import PlaidLink from 'react-plaid-link';


class BankAccounts extends React.Component{ 


  constructor(props) {
    super(props);
    this.state = {
      banks: null,
      addBankButtonClicked: false
    };
    this.handleBankRefresh = this.handleBankRefresh.bind(this);
    this.deleteBank = this.deleteBank.bind(this);
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
  }

  render (){ return(
      <AuthUserContext.Consumer>
      {authUser => (
        <div style={{paddingTop:  '10rem'}}>
           <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
           <h1>Bank Accounts</h1>
           <PlaidLink
              clientName="Betfully"
              env={plaidConst.ENVIRONMENT}
              product={["auth", "transactions"]}
              publicKey={plaidConst.PUBLIC_KEY}
              onExit={this.handleOnExit}
              onSuccess={this.handleOnSuccess}
              variant="primary" className="w-50 plaidLinkButton font-weight-bold">
              Add Bank Account
            </PlaidLink>
           <MapBanks banks={this.state.banks} onRefresh={this.handleBankRefresh} deleteBank={this.deleteBank}/>
        </div>
        )}
        </AuthUserContext.Consumer>
      );
  }

  handleOnSuccess(token, metadata) {
    // send token to client server
    console.log(token);
    console.log(metadata);
    console.log("Token "+this.props.currFirebaseToken);
    if(this.props.currFirebaseToken != null){
        const formData = new FormData();
        formData.append('FirebaseToken', this.props.currFirebaseToken);
        formData.append('PlaidPublicToken', token);
        axios.post(
            "/RegisterUserBankAccount",
            formData
            )
            .then(res => {
            console.log("Account Linked!");
            console.log(token);
            console.log(metadata);
        })
        .catch(error => {
            console.log(error);
        });
    }else{
      console.log("Something went wrong plaidLink");
    }
  }

  handleOnExit() {
  // handle the case when your user exits Link
  }

  componentDidMount(){
    console.log("Home mounted");
      const condition = authUser => !!authUser;
      if(condition && this.props.currFirebaseToken != null){
      const formData = new FormData();
      formData.append('FirebaseToken', this.props.currFirebaseToken)
      axios.post(
          "/GetUser",
          formData
        )
        .then(res => {
                  console.log(res.data);
                  console.log("Made call");
                  this.props.onUserRetrieved(JSON.stringify(res.data));
                  const user = JSON.parse(this.props.currUser);
                  console.log("Made call"+this.props.currUser.PlaidItemId);
                  if(user != null){
                    console.log("AS"+user.UserOffers);
                    console.log("AS"+user.Points);
                  //  console.log("AS"+user.Banks[0].Id);
                    this.setState({
                      banks : user.Banks
                    });
                  }else{
                    this.setState({
                      banks : null
                    });
                  }
                  
        })
        .catch(error => {
           console.log(error);
        });
            }else{

            }
  }

  deleteBank(bankId) {
    // handle the case when your user exits Link
        console.log("Will Delete "+bankId);

        if(this.props.currFirebaseToken != null){
          const formData = new FormData();
          formData.append('FirebaseToken', this.props.currFirebaseToken);
          formData.append('IntTag', bankId);
          axios.post(
              "/RemoveBankAccount",
              formData
              )
              .then(res => {
                if(res.data > 0){
                  console.log("Bank Account removed! "+bankId);
                  console.log(res.data);
                  
                }else{
                  console.log("Failed to delete bank: " + bankId);
                }
              
          })
          .catch(error => {
              console.log(error);
          });
      }
    }

    
  handleBankRefresh(bankId) {
    // send token to client server
    console.log("Token "+this.props.currFirebaseToken);
    console.log("Mking call "+bankId);
    if(this.props.currFirebaseToken != null){
        const formData = new FormData();
        formData.append('FirebaseToken', this.props.currFirebaseToken);
        formData.append('IntTag', bankId);
        axios.post(
            "/RequestPublicToken",
            formData
            )
            .then(res => {
            console.log("Public Token Retrieved!");
            console.log(res.data);
            const user = JSON.parse(this.props.currUser);
            if(user != null){
              for(var i = 0; i < user.Banks.length; i++){
                if(user.Banks[i].Id == bankId){
                  user.Banks[i] = {
                    ...user.Banks[i],
                    PlaidEnabled: res.data
                  }
                  console.log("Match found bank" + user.Banks[i].PlaidEnabled);
                  console.log("Match found"+user.UserOffers);
                  console.log("Match found"+user.Points);
                  this.setState({
                    banks : user.Banks
                  });
                }
              }
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
  }

}

function MapBanks(props){
  
    console.log("RENDER");
  var obj = props.banks;
  if(obj != null){
    const listItems =  obj.map(
      
      (number) => 
        <CardSection 
          BankAccounts={number.BankAccounts} 
          PlaidEnabled={number.PlaidEnabled} 
          onRefresh={props.onRefresh} 
          deleteBank={props.deleteBank} 
          key={number.Id} 
          RefreshRequired={number.RefreshRequired} 
          cardType={cardTypes.BANK} 
          AccountNum={number.Id} 
          CardImg={number.BetfullyUserId} 
          Status={number.Status}
          BankLogo={number.InstitutionLogo}
          BankName={number.InstitutionName}/>
      
    );
    
    return (<div  className="d-flex flex-column" >{listItems}<br /></div>);
  }else{
    return (<div  className="d-flex flex-column" >Loading...<br /></div>);
  }
}

const mapStateToProps = state => {
  return {
    currFirebaseToken: state.currFirebaseToken,
    currUser: state.currUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
    onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
  }
}

const condition = authUser => !!authUser;
export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(condition)(BankAccounts));