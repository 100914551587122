import React from 'react';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import CustomNavBar from '../CustomNavBar';
import SignOut from '../SignOut';


class Details extends React.Component{ 

  render (){ return(
      <AuthUserContext.Consumer>
      {authUser => (
        <div style={{paddingTop:  '10rem'}}>
           <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
           <h1>Account Details</h1>

          <PasswordForgetForm />
          <PasswordChangeForm />
          <SignOut/>
        </div>
        )}
        </AuthUserContext.Consumer>
      );
  }

}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(Details);