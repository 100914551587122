import React from 'react';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import CustomNavBar from '../CustomNavBar';
import CardSection from '../CardSection';
import SignOut from '../SignOut';
import * as cardTypes from '../../constants/cardTypes';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';


class AccountPage extends React.Component{ 

  constructor(props) {
    super(props);
    
  }

  componentDidMount(){
    const {firebaseToken, currUser } = this.props.location.state;
    this.props.onUserRetrieved(currUser);
  }

  render (){ return(
      <AuthUserContext.Consumer>
      {authUser => (
        <div style={{paddingTop:  '10rem', backgroundColor: 'lightgray'} }>
           <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
          <GetUserInfo  email={"Mario@ItsAMe.com"} userName={"Mario"} />
          <MapOffers/>

        </div>
        )}
        </AuthUserContext.Consumer>
      );
  }

}
function GetUserInfo(props){
  return <GuestInfo userName={props.userName} email={props.email}/>
}

function GuestInfo(props){
    return (
      <a href="/account/details" style={{textDecoration: "none"}}>
        <div  className="p-4 betfullyShadow1 d-flex justify-content-between " style={{backgroundColor:'black'}}>
          <div>
            <h1 className="mb-0 font-weight-bold" style={{color:'white'}}>{props.userName}</h1>
            <h6 style={{color:'white'}}>{props.email}</h6>
          </div>
          <h2 className="align-center" style={{color:'white'}}>
          &#9654;
          </h2>
        </div>
      </a>
    );
}

function MapOffers(){
  var obj = JSON.parse('['
  +'{"id":2, "title":"Transactions", "img":"/account/transactions", "desc":"on deposits up to $1,000 for new users only."},'
  +'{"id":3, "title":"Cashout", "img":"/account/cashout", "desc":"on winnings up to $10,000 for new and existing users."},'
  +'{"id":4, "title":"Bank Accounts", "img":"/account/bankaccounts", "desc":"on deposits up to $50k for new and existing users."}'
  +']');
  const listItems =  obj.map((number) => <CardSection key={number.id} cardType={cardTypes.ACCOUNT} CardTitle={number.title} CardImg={number.img}/>);

  return (<div  className="d-flex flex-column" >{listItems}<br /></div>);
}

const mapStateToProps = state => {
  return {
    currFirebaseToken: state.currFirebaseToken,
    currUser: state.currUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
    onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
  }
}

const condition = authUser => !!authUser;
export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(condition)(AccountPage));