import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from './Firebase';
import * as ROUTES from '../constants/routes';

const PasswordForgetPage = () => (
  <div>
    <PasswordForgetForm />
  </div>
);
const INITIAL_STATE = {
  email: '',
  error: null,
};
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <div >
        <div className="d-flex justify-content-center">
          <div className="signInSection ">
            <h1>Reset Password</h1>
            <form onSubmit={this.onSubmit}>
              <div className="d-flex flex-column" >
                <input
                  className="border-dark rounded-sm mb-2" style={{borderWidth: '1px'}}
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="  Email Address"
                />
                <button disabled={isInvalid} className="joinButton font-weight-bold pb-1 pt-1" type="submit">
                  Reset My Password
                </button>
                {error && <p>{error.message}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);
export default (PasswordForgetPage);
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
export { PasswordForgetForm, PasswordForgetLink };
