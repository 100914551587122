import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignUpLink } from './SignUp';
import { withFirebase } from './Firebase';
import * as ROUTES from '../constants/routes';
import { PasswordForgetLink } from './PasswordForget';
import CustomNavBar from './CustomNavBar';
import { FacebookLoginButton } from "react-social-login-buttons";
import GoogleButton from 'react-google-button'
// import { GoogleLoginButton } from "react-social-login-buttons";

const SignInPage = () => (
  <div>
    <SignInForm />
    
  </div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInAnonymousBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }
  onSubmit() {
    this.props.firebase
      .doCreateUserAnonymous()
      .then(socialAuthUser => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
  };
  render() {
    const { error } = this.state;
    return (
      <form >
        <GoogleButton type="dark"
         onClick={() => { this.onSubmit() }} className="h-100 w-100 googleButton d-flex align-items-center">{error && <p>{error.message}</p>}</GoogleButton>
        
        
      </form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }
  onSubmit() {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
  };
  render() {
    const { error } = this.state;
    return (
      <form >
        <GoogleButton type="light"
         onClick={() => { this.onSubmit() }} className="h-100 w-100 googleButton d-flex align-items-center">{error && <p>{error.message}</p>}</GoogleButton>
        
        
      </form>
    );
  }
}


class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }
  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase
          .user(socialAuthUser.user.uid)
          .set({
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            roles: {},
          });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  render() {
    const { error } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
        <FacebookLoginButton type="submit" className="rounded">
        <span>Continue with Facebook</span>
        </FacebookLoginButton>
        {/* <button type="submit">Sign In with Facebook</button> */}
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <div style={{paddingTop: this.props.showButton ? '13rem' : '10rem' }}>
        <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
        <div className="d-flex justify-content-center mt-3">
          <div className="signInSection ">
            <h1 style={{ textAlign:'center'}}>Sign In</h1>
            <SignInGoogle  />
            <SignInFacebook />
            <SignInAnonymous />
            <p className="mt-2" style={{color:'gray', textAlign:'center'}}>or</p>
            <form onSubmit={this.onSubmit}>
              <div className="d-flex flex-column" >
                <input
                  className="border-dark rounded-sm mb-2" style={{borderWidth: '1px'}}
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="  Email Address"
                />
                <input
                  className="border-dark rounded-sm mb-2" style={{borderWidth: '1px'}}
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  type="password"
                  placeholder="  Password"
                />
                <button disabled={isInvalid} className="joinButton font-weight-bold pb-1 pt-1" type="submit">
                  Sign In
                </button>
                {error && <p>{error.message}</p>}
              </div>
            </form>
            <br/>
            <PasswordForgetLink />
            <SignUpLink />
          </div>
        </div>
      </div>
    );
  }
}

const SignInAnonymous = compose(
  withRouter,
  withFirebase,
)(SignInAnonymousBase);

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

export default SignInPage;
export { SignInForm, SignInGoogle, SignInFacebook, SignInAnonymous };