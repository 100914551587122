import React from 'react'
import './Home.css';
import CustomNavBar from './CustomNavBar';
import CustomBottomNavBar from './CustomBottomNavBar';
import GrabOffers from '../GrabOffers';
import * as actionTypes from '../store/actions';
import { connect } from 'react-redux';
import axios from '../axios';

import { withAuthorization } from './Session';

class Home extends React.Component{ 

    constructor(props) {
      super(props);
      this.state = {
       
        listNumber: 0,
        offerList: null
      };
      this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
      console.log("Home mounted");
      const condition = authUser => !!authUser;
      if(condition && this.props.currFirebaseToken != null){
        console.log("token homea:" + this.props.currFirebaseToken);
      const formData = new FormData();
      formData.append('FirebaseToken', this.props.currFirebaseToken)
      axios.post(
          "/GetUser",
          formData
        )
        .then(res => {
                  console.log(res.data);
                  console.log("Made call");
                  this.props.onUserRetrieved(JSON.stringify(res.data));
                  const user = JSON.parse(this.props.currUser);
                  if(user != null){
                    console.log("AS"+user.UserOffers);
                    console.log("AS"+user.Points);
                   // console.log("AS"+user.Banks[0].Id);
                    this.setState({
                      listNumber : 0,
                      offerList: user.UserOffers,
                      points : user.Points,
                      banks : user.Banks
                    });
                  }else{
                    this.setState({
                      listNumber : 0,
                      offerList: null,
                      points : 0,
                      banks : null
                    });
                  }
                  
        })
        .catch(error => {
           console.log(error);
        });
            }else{

            }
    }

    render (){
        return(
          <div style={{paddingTop: this.props.showButton ? '13rem' : '10rem', backgroundColor: 'lightgray', height: '100vh', overflow:'auto', height: 'inherit'} }>
              <CustomNavBar onClick={this.handleClick} showButton = {this.props.showButton} points={this.state.points} />
              
              <GrabOffers handleOfferSelect={this.handleOfferSelect} tabNumber = {this.state.listNumber} UserOffers = {this.state.offerList} FirebaseToken={this.props.currFirebaseToken}/>
              {this.state.banks != null && this.state.banks.length == 0 &&
              <CustomBottomNavBar />}
          </div>
        );
    }

    handleOfferSelect(offerId) {
      // send offerid to client server
      console.log("Home: Offer selected: "+offerId);

    }

    handleClick(i) {
      if(this.state.listNumber !== i){
        this.setState({
          listNumber : i
        });
  
      }
    }
}

const mapStateToProps = state => {
  return {
    currFirebaseToken: state.currFirebaseToken,
    currUser: state.currUser
    // betfullyUser: {
    //     ...state.betfullyUser
    // }
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
    onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
  //  onUserRetrieved: (user) => dispatch({type: actionTypes.GET_USER, id: user.Id, firebaseUserId: user.FirebaseUserId, points: user.Points})
  }
}

const condition = authUser => !!authUser;
export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(condition)(Home));