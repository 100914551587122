import React from 'react';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import CustomNavBar from '../CustomNavBar';
import CardSection from '../CardSection';
import SignOut from '../SignOut';
import axios from '../../axios';
import { usePlaidLink } from 'react-plaid-link';

import * as actionTypes from '../../store/actions';
import { connect } from 'react-redux';
import * as cardTypes from '../../constants/cardTypes';
import { withRouter } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class OfferDetails extends React.Component{ 


  constructor(props) {
    super(props);
    this.state = {
      userOfferItem: null
    };
    
    this.activateOffer = this.activateOffer.bind(this);
  }

  render (){ 
    console.log("UOI " + this.state.userOfferItem);
    return(
      <AuthUserContext.Consumer>
        {authUser => (
          <div style={{paddingTop:  '10rem'}}>
            <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
            <h1>Offer Details</h1>
            {this.state.userOfferItem != null && 
              <UserOfferCard userIO={this.state.userOfferItem} clickFunc={this.activateOffer}/>
            }
            {this.state.userOfferItem != null && this.state.userOfferItem.BetfullyTransactions != null &&
              this.state.userOfferItem.BetfullyTransactions.map(
                (number) => 
                  <CardSection 
                    key={number.Date} 
                    cardType={cardTypes.BETFULLY_TRANSACTION} 
                    ClientId={number.Client} 
                    PointsAwarded={number.PointsAwarded}  
                    AmountProcessed={number.AmountProcessed}
                    Date={number.Date} 
                    />)
            }
           
          </div>
          )}
        </AuthUserContext.Consumer>
      );
  }

  componentDidMount(){
    console.log("OfferDetails mounted");
    const {firebaseToken, offerId, currUser } = this.props.location.state;
    console.log("OfferDetails mounted "+offerId);
    console.log("OfferDetails mounted "+firebaseToken);
    console.log("OfferDetails mounted "+currUser);
    this.props.onUserRetrieved(currUser);
      const condition = authUser => !!authUser;
      if(condition && this.props.currFirebaseToken != null){
        console.log("OfferDetails call made");
        this.grabDetails(offerId, this.props.currFirebaseToken);
      }else{
        console.log("OfferDetails cannot make call");
      }
  }

  grabDetails(offerId, firebaseToken) {
    
    const formData = new FormData();
    formData.append('FirebaseToken', firebaseToken);
    formData.append('IntTag', offerId);
    axios.post(
        "/GetUserOfferDetails",
        formData
        )
        .then(res => {
          if(res.data != null){
            console.log("Offer Details Grabbed");
            console.log(res.data);
            this.setState({
              userOfferItem : res.data
            });
          }else{
            this.setState({
              userOfferItem : null
            });
            console.log("Failed to grab offer details " + offerId);
          }
        })
        .catch(error => {
            console.log(error);
        });
  }

  activateOffer() {
    // send token to client server
    console.log("ACTIVATE OFFER "+ this.state.userOfferItem.Id);
    if(this.props.currFirebaseToken != null){
        const formData = new FormData();
        formData.append('FirebaseToken', this.props.currFirebaseToken);
        formData.append('IntTag', this.state.userOfferItem.Id);
        axios.post(
            "/ActivateOffer",
            formData
            )
            .then(res => {
            console.log("Offer Activated!");
            console.log(res.data);
            this.setState({
              userOfferItem : res.data
            });
        })
        .catch(error => {
            console.log(error);
        });
    }
  }
}



function MapBanks(props){
  
    console.log("RENDER");
  var obj = props.banks;
  if(obj != null){
    const listItems =  obj.map(
      
      (number) => 
        <CardSection BankAccounts={number.BankAccounts} PlaidEnabled={number.PlaidEnabled} onRefresh={props.onRefresh} deleteBank={props.deleteBank} key={number.Id} RefreshRequired={number.RefreshRequired} cardType={cardTypes.BANK} AccountNum={number.Id} CardImg={number.BetfullyUserId} Status={number.Status}/>
      
    );
    
    return (<div  className="d-flex flex-column" >{listItems}<br /></div>);
  }else{
    return (<div  className="d-flex flex-column" >Loading...<br /></div>);
  }
}

const mapStateToProps = state => {
  return {
    currFirebaseToken: state.currFirebaseToken,
    currUser: state.currUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
    onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
  }
}

function UserOfferCard(props){
  const userOfferItem = props.userIO;
  console.log("UserOI "+userOfferItem);
  return (
      <Card  className="cardItem ">
          {/* className="justify-content-around" */}
          <Row  >
              {/* {userOfferItem.Id != null &&
                <Col xs={2} className="text-center">
                  Your offer Id is {userOfferItem.Id}
                </Col>
              } */}
              <Col >
                  
              </Col>
              <Col xs={3} className="context-center">
              
                  <Card.Img  className="cardImage pt-4 pb-1"   src={"/assets/BetFully_v1-06.png"}/>
              </Col>
              
              <Col >
                 
              </Col>
          </Row>
          <Row  >
          {userOfferItem.mOfferItem != null && userOfferItem.mOfferItem.Title != null &&
                <Col  className="text-center">
                   <h1>{userOfferItem.mOfferItem.Title}</h1>
                </Col>
              }
              </Row>
              <Row>
              {userOfferItem.mOfferItem != null && userOfferItem.mOfferItem.Title != null &&
                <Col className="text-center">
                  <h5>{userOfferItem.mOfferItem.Description}</h5>
                </Col>
              }
              </Row>
              <Row>
              {userOfferItem.Status != null &&
                <Col  className="text-center">
                   <OfferStatus Status={userOfferItem.Status}/>
                </Col>
              }
          </Row>
          <Row  >
              {userOfferItem.ExpirationDate != null &&
                <Col  className="text-center">
                   <ExpirationDate ExpirationDate={userOfferItem.ExpirationDate}/>
                </Col>
              }
          </Row>
          <Row  >
              {userOfferItem.mOfferItem != null && userOfferItem.Status !== 0 &&
                <Col  className="text-center">
                  
                   <h2>{(userOfferItem.mOfferItem.Reward > 0)?userOfferItem.AmountProcessed*100 / userOfferItem.mOfferItem.Reward*100 + "% Complete":"0% Complete" }</h2>
                </Col>
              }
          </Row>
          <Row  >
              {userOfferItem != null && userOfferItem.Status === 0 &&
                <Col  className="text-center">
                   <ActivateButton clickFunc={props.clickFunc}/>
                </Col>
              }
          </Row>
          
      </Card>
  );
}

function OfferStatus(props){
  switch(props.Status){
    case 0: //None
      return(<h1>Status: Available</h1>);
    case 1: //Active
      return(<h1>Status: Active</h1>);
    case 2: //Expired
      return(<h1>Status: Expired</h1>);
    case 3: //Cancelled
      return(<h1>Status: Cancelled</h1>);
    case 4: //Completed
      return(<h1>Status: Completed</h1>);
    default://None
      return(<h1>Status: unknown</h1>);
  }
}

function ExpirationDate(props){
  return(<h4>Expires: {props.ExpirationDate}</h4>);
  // switch(props.ExpirationDate){
  //   case 0: //None
  //     return(<h1>Status: Available</h1>);
  //   case 1: //Active
  //     return(<h1>Status: Active</h1>);
  //   case 2: //Expired
  //     return(<h1>Status: Expired</h1>);
  //   case 3: //Cancelled
  //     return(<h1>Status: Cancelled</h1>);
  //   case 4: //Completed
  //     return(<h1>Status: Completed</h1>);
  //   default://None
  //     return(<h1>Status: unknown</h1>);
  // }
}

function ActivateButton(props){
  return(<button className="w-50 plaidLinkButton font-weight-bold" onClick={() =>props.clickFunc()}>Activate</button>);
  // switch(props.ExpirationDate){
  //   case 0: //None
  //     return(<h1>Status: Available</h1>);
  //   case 1: //Active
  //     return(<h1>Status: Active</h1>);
  //   case 2: //Expired
  //     return(<h1>Status: Expired</h1>);
  //   case 3: //Cancelled
  //     return(<h1>Status: Cancelled</h1>);
  //   case 4: //Completed
  //     return(<h1>Status: Completed</h1>);
  //   default://None
  //     return(<h1>Status: unknown</h1>);
  // }
}

const condition = authUser => !!authUser;
export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(condition)(OfferDetails));