import React from 'react';
import { AuthUserContext, withAuthorization } from '../../Session';
import CustomNavBar from '../../CustomNavBar';
import CardSection from '../../CardSection';
import axios from '../../../axios';

import * as actionTypes from '../../../store/actions';
import { connect } from 'react-redux';
import * as cardTypes from '../../../constants/cardTypes';

class BankAccountDetails extends React.Component{ 

  constructor(props) {
    super(props);
    this.state = {
      bankAccount: null
    };
    this.handleBankDetails = this.handleBankDetails.bind(this);
  }

  render (){ return(
      <AuthUserContext.Consumer>
      {authUser => (
        <div style={{paddingTop:  '10rem'}}>
           <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
           <h1>Bank Account Details</h1>
           {this.state.bankAccount != null && this.state.bankAccount.AllTransactions != null &&
                this.state.bankAccount.AllTransactions.map(
                  (number) => 
                    <CardSection 
                      key={number.PlaidTransactionId} 
                      cardType={cardTypes.FULL_TRANSACTION} 
                      PlaidAmount={number.PlaidAmount}
                      PlaidName={number.PlaidName}
                      Processed={number.Processed}
                      PlaidDate={number.PlaidDate}
                      />)
            }
        </div>
        )}
        </AuthUserContext.Consumer>
      );
  }

  
  componentDidMount(){
    const condition = authUser => !!authUser;
    const {firebaseToken, bankAccountId, currUser } = this.props.location.state;
    if(currUser != null){
      this.props.onUserRetrieved(currUser);
    }
    console.log("Id clicked: "+bankAccountId);
    if(condition && this.props.currFirebaseToken != null){
      if(bankAccountId != null && bankAccountId > 0){
        this.handleBankDetails(bankAccountId);
      }
    }else{

    }
  }

  handleBankDetails(bankId) {
    // send token to client server
    console.log("Token "+this.props.currFirebaseToken);
    console.log("Mking call "+bankId);
    if(this.props.currFirebaseToken != null){
        const formData = new FormData();
        formData.append('FirebaseToken', this.props.currFirebaseToken);
        formData.append('IntTag', bankId);
        axios.post(
            "/GetBankTransactionHistory",
            formData
            )
            .then(res => {
            console.log("Bank History Retrieved!");
            console.log(res.data);
            this.setState({
              bankAccount : res.data
            });
        })
        .catch(error => {
            console.log(error);
        });
    }
  }
}

function MapBanks(props){
  
    console.log("RENDER");
  var obj = props.banks;
  if(obj != null){
    const listItems =  obj.map(
      
      (number) => 
        <CardSection BankAccounts={number.BankAccounts} PlaidEnabled={number.PlaidEnabled} onRefresh={props.onRefresh} deleteBank={props.deleteBank} key={number.Id} RefreshRequired={number.RefreshRequired} cardType={cardTypes.BANK} AccountNum={number.Id} CardImg={number.BetfullyUserId} Status={number.Status}/>
      
    );
    
    return (<div  className="d-flex flex-column" >{listItems}<br /></div>);
  }else{
    return (<div  className="d-flex flex-column" >Loading...<br /></div>);
  }
}

const mapStateToProps = state => {
  return {
    currFirebaseToken: state.currFirebaseToken,
    currUser: state.currUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
    onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
  }
}

const condition = authUser => !!authUser;
export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(condition)(BankAccountDetails));