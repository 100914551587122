import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './components/Home';
import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import PasswordForgetPage from './components/PasswordForget';
import AccountPage from './components/Account/Account';
import * as ROUTES from './constants/routes';
import withAuthentication from './components/Session/withAuthentication';
import AccountDetails from './components/Account/Details';
import FavoriteSites from './components/Account/FavoriteSites';
import OfferDetails from './components/Offer/OfferDetails';
import Transactions from './components/Account/Transactions';
import Cashout from './components/Account/Cashout';
import BankAccounts from './components/Account/BankAccounts';
import Rewards from './components/Rewards/Rewards';
import RewardDetails from './components/Rewards/RewardDetails';
import BankAccountDetails from './components/Account/BankAccountPages/BankAccountDetails';

class App extends React.Component{ 

  constructor(props) {
    super(props);
    this.state = {
     
      listNumber: 0
    };
  }

  render(){
    return(
      <Router>
        <div className="outer">
          
          <Switch>
            <Route exact path={ROUTES.LANDING} render={(props) =><Home {...props} showButton={true} />} />
            {/* <LandingPage {...props} showButton={false} />} /> */}
            <Route path={ROUTES.SIGN_UP} render={(props) => <SignUpPage {...props} showButton={true} />} />
            <Route path={ROUTES.SIGN_IN} render={(props) => <SignInPage {...props} showButton={true} />} />
            <Route path={ROUTES.PASSWORD_FORGET} render={(props) => <PasswordForgetPage {...props} showButton={true} />} />
            <Route path={ROUTES.HOME} render={(props) => <Home {...props} showButton={true} />} />
            <Route path={ROUTES.ACCOUNT} exact={true} render={(props) => <AccountPage {...props} showButton={false} />} />
            <Route path={ROUTES.ACCOUNT_DETAILS} render={(props) => <AccountDetails {...props} showButton={false} />} />
            <Route path={ROUTES.OFFER_DETAILS} render={(props) => <OfferDetails {...props} showButton={true} />} />
            <Route path={ROUTES.ACCOUNT_FAV} render={(props) => <FavoriteSites {...props} showButton={false} />} />
            <Route path={ROUTES.ACCOUNT_TRANSACTIONS} render={(props) => <Transactions {...props} showButton={false} />} />
            <Route path={ROUTES.ACCOUNT_CASHOUT} render={(props) => <Cashout {...props} showButton={false} />} />
            <Route path={ROUTES.REWARDS} exact={true} render={(props) => <Rewards {...props} showButton={false} />} />
            <Route path={ROUTES.REWARD_DETAILS} exact={true} render={(props) => <RewardDetails {...props} showButton={false} />} />
            <Route path={ROUTES.ACCOUNT_ACCOUNTS} render={(props) => <BankAccounts {...props} showButton={false} />} />
            <Route path={ROUTES.BANKACCOUNT_DETAILS} render={(props) => <BankAccountDetails {...props} showButton={false} />} />
            <Route exact path={"*"} render={(props) =><Home {...props} showButton={true} />} />
          </Switch>
          {/* <Route path={ROUTES.ADMIN} component={AdminPage} /> */}
          {/* <Route path={ROUTES.ADMIN} component={AdminPage} /> */}

          {/* <Navigation/> */}

        </div>
      </Router>
    );
  }

  

}



export default withAuthentication(App);

// export default App;

