import React, {Component} from 'react'

import PlaidLink from 'react-plaid-link';
import axios from '../axios';
import { connect } from 'react-redux';
import * as plaidConst from '../constants/plaidConstants';

import './CustomBottomNavBar.css';
import * as actionTypes from '../store/actions';
// import Background from 'BetFully_v1-03.png'


class CustomBottomNavBar extends Component{
    constructor(props) {
        super(props);
        this.handleOnSuccess = this.handleOnSuccess.bind(this);
    }

    render(){
        return (
                <div className="navContainer text-white position-fixed fixed-bottom"  >
                    
                    <p className="text-center mt-1 ">Link Your Account to Get Cash Back</p>
                    <div className="text-center ">
                        <PlaidLink
                            clientName="Betfully"
                            env={plaidConst.ENVIRONMENT}
                            product={["auth", "transactions"]}
                            publicKey={plaidConst.PUBLIC_KEY}
                            onExit={this.handleOnExit}
                            onSuccess={this.handleOnSuccess}
                            variant="primary" className="w-50 plaidLinkButton font-weight-bold">
                            Link Account Now
                        </PlaidLink>
                    </div>
                </div>

                
        )
    }

    handleOnSuccess(token, metadata) {
        // send token to client server
        console.log(token);
        console.log(metadata);
        console.log("Token "+this.props.currFirebaseToken);
        if(this.props.currFirebaseToken != null){
            const formData = new FormData();
            formData.append('FirebaseToken', this.props.currFirebaseToken);
            formData.append('PlaidPublicToken', token);
            axios.post(
                "/RegisterUserBankAccount",
                formData
                )
                .then(res => {
                console.log("Account Linked!");
                console.log(token);
                console.log(metadata);
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
    
    handleOnExit() {
    // handle the case when your user exits Link
    }
      
    //   public class GenericMobileAppRequestJson {
    //     public string FirebaseToken { get; set; }
    //     public string PlaidPublicToken { get; set; }
    //     public int OfferId { get; set; }
    // }
}

const mapStateToProps = state => {
    return {
      currFirebaseToken: state.currFirebaseToken,
      currUser: state.currUser
      // betfullyUser: {
      //     ...state.betfullyUser
      // }
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
      onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
    //  onUserRetrieved: (user) => dispatch({type: actionTypes.GET_USER, id: user.Id, firebaseUserId: user.FirebaseUserId, points: user.Points})
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomBottomNavBar);