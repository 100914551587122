import app from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD8mqe_mytlZaxl_fdV1AKYubQHrwIrYpw",
    authDomain: "react-betfully.firebaseapp.com",
    databaseURL: "https://react-betfully.firebaseio.com",
    projectId: "react-betfully",
    storageBucket: "react-betfully.appspot.com",
    messagingSenderId: "835654908856",
    appId: "1:835654908856:web:d91c7125fa625bc225ed4d",
    measurementId: "G-MHJMKRQEED"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();

    this.googleProvider = new app.auth.GoogleAuthProvider();

    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  // *** Auth API ***
  doCreateUserAnonymous = () =>
    this.auth.signInAnonymously();

  getFbToken = () =>
    this.auth.get();

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  //getIdToken = () => this.auth.currentUser.getIdToken
    /*
    .getIdToken(forceRefresh  true).then(function(idToken) {
                // Send token to your backend via HTTPS
                // ...
                console.log("user token: "+idToken);
              }).catch(function(error) {
                // Handle error
                console.log("error");
              });
    */

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);


}


export default Firebase;