import React, {Component} from 'react'
import {Nav, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import './CustomNavBar.css';
import * as ROUTES from '../constants/routes';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import { withFirebase } from './Firebase';
import {Link} from 'react-router-dom'
// import Background from 'BetFully_v1-03.png'


class CustomNavBar extends Component{


    constructor(props) {
        super(props);
        this.state = {
            user: null
        };
      }

    render(){
        return (
           <div className="position-fixed fixed-top" >
                <div className="navContainerTop"  >
                    <this.topHeaders user={this.state.user} points={this.props.points} currUser={this.props.currUser}/>
                    <div className="navContainerTopLogo"  />
                </div>
                <div className="d-flex flex-column buttonParent" style={{visibility: this.props.showButton ? 'visible' : 'hidden'}}>
                    <ToggleButtonGroup type="radio" name="options" defaultValue={1} > 
                        <ToggleButton value={1} className=" font-weight-bold rounded-0 topButtons" onClick={()=>this.props.onClick(1)} >Cash Back</ToggleButton>
                        <ToggleButton value={2} className=" font-weight-bold rounded-0 topButtons"  onClick={()=>this.props.onClick(2)}>Offers</ToggleButton>
                        <ToggleButton value={3} className=" font-weight-bold rounded-0 topButtons"  onClick={()=>this.props.onClick(3)}>Free Money</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
                this.setState({
                    user: authUser
                  });
            },
          );

          
      }

      componentWillUnmount() {
        this.listener();
      }
    topHeaders(props){
        
        if(props.user != null){
            console.log("user: "+props.currUser);
            const user = JSON.parse(props.currUser);
            let userId = 0;
            let userPoints = 0;
            if(user != null){
                console.log("AS"+user.UserOffers);
                console.log("AS"+user.Points);
                // console.log("AS"+user.Banks[0].Id);
                userId = user.Id;
                userPoints = user.Points;
            }

            return (<Nav activeKey={ROUTES.HOME} className="justify-content-between pb-1">
            <Nav.Item  >
                <Link to={{
                    pathname: '/account',
                    state: {
                        //firebaseToken: this.props.currFirebaseToken,
                        //offerId : this.props.OfferId,
                        currUser: props.currUser
                    }
                }}>
                {/* <Nav.Link href={ROUTES.ACCOUNT}> */}
                    <div className="topText font-weight-bold">user#{userId}</div><div className="topText"> Account not yet linked</div>
                {/* </Nav.Link> */}
                </Link>
            </Nav.Item>
            <Nav.Item>
            <Link to={{
                    pathname: '/reward',
                    state: {
                        //firebaseToken: this.props.currFirebaseToken,
                        //offerId : this.props.OfferId,
                        currUser: props.currUser
                    }
                }}>
                {/* <Nav.Link eventKey="link-1" href={ROUTES.REWARDS}> */}
                    <div className="d-flex align-items-center">
                        <div className="topText">
               
                            <div className="font-weight-bold text-right">{userPoints} Pts</div><div className="text-right"> 2 New Transactions</div>
                        </div>
                        <div className="topText pl-2">&#9654;</div>

                    </div>
                    </Link>
                {/* </Nav.Link> */}
            </Nav.Item>
            </Nav>);
        }else{
            return (<Nav activeKey={ROUTES.HOME} className="justify-content-between pb-1">
            <Nav.Item  >
                <Nav.Link href={ROUTES.SIGN_UP}><div className="topText font-weight-bold">Join Now</div><div className="topText"> </div></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="link-1" href={ROUTES.SIGN_IN}>
                    <div className="d-flex align-items-center">
                        <div className="topText">
                            <div className="font-weight-bold text-right">Sign In</div><div className="text-right"> </div>
                        </div>
                        {/* <div className="topText pl-2">&#10148; */}
                        {/* </div> */}
                    </div>
                </Nav.Link>
            </Nav.Item>
            </Nav>);
        }
    }

}

const mapStateToProps = state => {
    return {
      currFirebaseToken: state.currFirebaseToken,
      currUser: state.currUser
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
      onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
    //  onUserRetrieved: (user) => dispatch({type: actionTypes.GET_USER, id: user.Id, firebaseUserId: user.FirebaseUserId, points: user.Points})
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(CustomNavBar));
