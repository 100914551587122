import React from 'react';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import CustomNavBar from '../CustomNavBar';
import SignOut from '../SignOut';
import axios from '../../axios';
import CardSection from '../CardSection';
import * as cardTypes from '../../constants/cardTypes';
import * as actionTypes from '../../store/actions';
import { connect } from 'react-redux';

class Transactions extends React.Component{ 

  constructor(props) {
    super(props);
    this.state = {
      userTransactions: null
    };
  }

  componentDidMount(){
    const {firebaseToken, currUser } = this.props.location.state;
    this.props.onUserRetrieved(currUser);
  }
  
  render (){ return(
      <AuthUserContext.Consumer>
      {authUser => (
        <div style={{paddingTop:  '10rem'}}>
           <CustomNavBar onClick={i => this.handleClick(i)} showButton = {this.props.showButton} />
           <h1>Transaction History</h1>
           {this.state.userTransactions != null &&
                this.state.userTransactions.map(
                  (number) => 
                    <CardSection 
                      key={number.Date} 
                      cardType={cardTypes.BETFULLY_TRANSACTION} 
                      ClientId={number.Client} 
                      PointsAwarded={number.PointsAwarded}  
                      AmountProcessed={number.AmountProcessed}
                      Date={number.Date} 
                      />)
            }
        </div>
        )}
        </AuthUserContext.Consumer>
      );
  }

  componentDidMount(){
    console.log("Transactions mounted");
      const condition = authUser => !!authUser;
      if(condition && this.props.currFirebaseToken != null){
        console.log("TransactionHistory call made");
        this.grabDetails(this.props.currFirebaseToken);
      }else{
        console.log("TransactionHistory cannot make call");
      }
  }

  grabDetails(firebaseToken) {
    
    const formData = new FormData();
    formData.append('FirebaseToken', firebaseToken);
    axios.post(
        "/GetUserTransactionHistory",
        formData
        )
        .then(res => {
          if(res.data != null){
            console.log("Transaction History Grabbed");
            console.log(res.data);
            this.setState({
              userTransactions : res.data
            });
          }else{
            this.setState({
              userTransactions : null
            });
            console.log("Failed to grab Transaction History ");
          }
        })
        .catch(error => {
            console.log(error);
        });
  }

}

const mapStateToProps = state => {
  return {
    currFirebaseToken: state.currFirebaseToken,
    currUser: state.currUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
    onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
  }
}

const condition = authUser => !!authUser;
export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(condition)(Transactions));