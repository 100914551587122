import * as actionTypes from './actions';

const initialState = {
    ingredients : null,
    currFirebaseToken : null,
    currUser: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.ADD_INGREDIENTS:
            return {
                ...state,
                ingredients: {
                    ...state.ingredients
                }   
            };
        case actionTypes.SAVE_TOKEN:
            return {
                ...state,
                currFirebaseToken : action.firebaseToken
            };
        case actionTypes.SAVE_USER:
            return {
                ...state,
                currUser : action.user
            };
        default:
            return state;
    }
};

export default reducer;