import React, {Component} from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import PlaidLink from 'react-plaid-link'
import './CardSection.css';
import * as cardTypes from '../constants/cardTypes';
import * as ROUTES from '../constants/routes';
import * as plaidConst from '../constants/plaidConstants';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';


class CardSection extends Component{

    constructor(props) {
        super(props);
        this.state = {
          enabled: true
        };
        this.setEnabled = this.setEnabled.bind(this);
      }

    render() {
        if(this.props.BankAccounts != null){
            console.log("BANKACCOUNTS: " + this.props.BankAccounts);

        }
        switch(this.props.cardType){
            case cardTypes.OFFER:
                return(
                    <Link to={{
                        pathname: ROUTES.OFFER_DETAILS,
                        state: {
                            firebaseToken: this.props.currFirebaseToken,
                            offerId : this.props.OfferId,
                            currUser: this.props.currUser
                        }
                        }}>
                            <OfferCard FirebaseToken={this.props.FirebaseToken} OfferId={this.props.OfferId} CardDesc={this.props.CardDesc} CardImg={this.props.CardImg} CardTitle={this.props.CardTitle} offerClicked={this.props.offerClicked}/>
                        </Link>
                );
            case cardTypes.ACCOUNT:
                return(
                    <Link to={{
                        pathname: this.props.CardImg,
                        state: {
                            firebaseToken: this.props.currFirebaseToken,
                            currUser: this.props.currUser
                        }
                    }}>
                        <AccountCard CardTitle={this.props.CardTitle} CardImg={this.props.CardImg}/>
                    </Link>
                );
            case cardTypes.BANK:
                switch(this.props.Status){
                    case 0:
                        return(<div><BankCard bankName={this.props.BankName} bankLogo={this.props.BankLogo} deleteBank={this.props.deleteBank} handleOnExit={this.handleOnExit} handleOnSuccess={this.handleOnSuccess} PlaidEnabled={this.props.PlaidEnabled} buttonOn = {this.state.enabled} onEnabled={this.setEnabled} onRefresh={this.props.onRefresh} CardTitle={this.props.CardTitle} CardImg={this.props.CardImg} AccountNum={this.props.AccountNum} RefreshRequired={this.props.RefreshRequired} Status={"None"}/>
                        <MapBankAccounts BankAccounts={this.props.BankAccounts}/></div>)
                    case 1:
                        return(<div><BankCard bankName={this.props.BankName} bankLogo={this.props.BankLogo} deleteBank={this.props.deleteBank} handleOnExit={this.handleOnExit} handleOnSuccess={this.handleOnSuccess} PlaidEnabled={this.props.PlaidEnabled} buttonOn = {this.state.enabled} onEnabled={this.setEnabled} onRefresh={this.props.onRefresh} CardTitle={this.props.CardTitle} CardImg={this.props.CardImg} AccountNum={this.props.AccountNum} RefreshRequired={this.props.RefreshRequired} Status={"Active"}/>
                        <MapBankAccounts BankAccounts={this.props.BankAccounts}/></div>)
                    case 2:
                        return(<div><BankCard bankName={this.props.BankName} bankLogo={this.props.BankLogo} deleteBank={this.props.deleteBank} handleOnExit={this.handleOnExit} handleOnSuccess={this.handleOnSuccess} PlaidEnabled={this.props.PlaidEnabled} buttonOn = {this.state.enabled} onEnabled={this.setEnabled} onRefresh={this.props.onRefresh} CardTitle={this.props.CardTitle} CardImg={this.props.CardImg} AccountNum={this.props.AccountNum} RefreshRequired={this.props.RefreshRequired} Status={"Removed"}/>
                        <MapBankAccounts BankAccounts={this.props.BankAccounts}/></div>)
                    case 3:
                        return(<div><BankCard bankName={this.props.BankName} bankLogo={this.props.BankLogo} deleteBank={this.props.deleteBank} handleOnExit={this.handleOnExit} handleOnSuccess={this.handleOnSuccess} PlaidEnabled={this.props.PlaidEnabled} buttonOn = {this.state.enabled} onEnabled={this.setEnabled} onRefresh={this.props.onRefresh} CardTitle={this.props.CardTitle} CardImg={this.props.CardImg} AccountNum={this.props.AccountNum} RefreshRequired={this.props.RefreshRequired} Status={"Inactive"}/>
                        <MapBankAccounts BankAccounts={this.props.BankAccounts}/></div>)
                    case 4:
                        return(<div><BankCard bankName={this.props.BankName} bankLogo={this.props.BankLogo} deleteBank={this.props.deleteBank} handleOnExit={this.handleOnExit} handleOnSuccess={this.handleOnSuccess} PlaidEnabled={this.props.PlaidEnabled} buttonOn = {this.state.enabled} onEnabled={this.setEnabled} onRefresh={this.props.onRefresh} CardTitle={this.props.CardTitle} CardImg={this.props.CardImg} AccountNum={this.props.AccountNum} RefreshRequired={this.props.RefreshRequired} Status={"Pending"}/>
                        <MapBankAccounts BankAccounts={this.props.BankAccounts}/></div>)
                    default:
                        return(<div><BankCard bankName={this.props.BankName} bankLogo={this.props.BankLogo} deleteBank={this.props.deleteBank} handleOnExit={this.handleOnExit} handleOnSuccess={this.handleOnSuccess} PlaidEnabled={this.props.PlaidEnabled} buttonOn = {this.state.enabled} onEnabled={this.setEnabled} onRefresh={this.props.onRefresh} CardTitle={this.props.CardTitle} CardImg={this.props.CardImg} AccountNum={this.props.AccountNum} RefreshRequired={this.props.RefreshRequired} Status={"Processing"}/>
                        <MapBankAccounts BankAccounts={this.props.BankAccounts}/></div>)
                }
                
            case cardTypes.BANK_ACCOUNT:
                return(
                    <Link to={{
                        pathname: ROUTES.BANKACCOUNT_DETAILS,
                        state: {
                            firebaseToken: this.props.currFirebaseToken,
                            bankAccountId : this.props.bankAccountId,
                            currUser: this.props.currUser
                        }
                        }}>
                        <AccountCard CardTitle={this.props.Name} Mask={this.props.Mask}/>
                    </Link>
                );
            case cardTypes.BETFULLY_TRANSACTION:
                return(
                <BetfullyTransactionCard 
                    ClientId={this.props.ClientId} 
                    PointsAwarded={this.props.PointsAwarded}
                    AmountProcessed={this.props.AmountProcessed}
                    Date={this.props.Date}/>);
            case cardTypes.REWARD:
                console.log("Reward Id is :"+this.props.RewardId);
                return(
                    <Link to={{
                        pathname: ROUTES.REWARD_DETAILS,
                        state: {
                            firebaseToken: this.props.currFirebaseToken,
                            rewardId : this.props.RewardId,
                            currUser: this.props.currUser
                        }
                        }}>
                        <RedeemPointsCard 
                                CardTitle={this.props.Title}
                                CardDesc={this.props.Description}
                                PointsCost={this.props.PointsCost}
                                RewardType={this.props.RewardType}
                            />
                     </Link>);
            case cardTypes.FULL_TRANSACTION:
                return(
                <FullTransactionCard 
                    IsProcessed={this.props.Processed} 
                    PointsAwarded={this.props.PlaidName}
                    AmountProcessed={this.props.PlaidAmount}
                    Date={this.props.PlaidDate}/>)
            case cardTypes.REDEEMED_REWARD:
                return(
                    
                <FullTransactionCard 
                    IsProcessed={true} 
                    PointsAwarded={this.props.PointsValue}
                    AmountProcessed={this.props.CashValue}
                    Date={this.props.DateClaimed}/>);
            default:
                return(<AccountCard CardTitle={this.props.CardTitle} CardImg={this.props.CardImg}/>)
        }
        // if(this.props.CardDesc != null){
        //     return(<OfferCard CardDesc={this.props.CardDesc} CardImg={this.props.CardImg} CardTitle={this.props.CardTitle}/>)
        // }else{
        //     return(<AccountCard CardTitle={this.props.CardTitle} CardImg={this.props.CardImg}/>)
        // }
    } 

    handleBankRefresh(bankId) {
        // send token to client server
        console.log("Token "+this.props.currFirebaseToken);
        console.log("Mking call "+bankId);
        if(this.props.currFirebaseToken != null){
            
        }
      }

    setEnabled(isEnabled){
        this.setState({
            enabled : isEnabled
          });
    }

    handleOnSuccess(token, metadata) {
        // send token to client server
        console.log("Onsuccess " + token);
        console.log(metadata);
        // console.log("Token "+this.props.currFirebaseToken);
        // if(this.props.currFirebaseToken != null){
        //     const formData = new FormData();
        //     formData.append('FirebaseToken', this.props.currFirebaseToken);
        //     formData.append('PlaidPublicToken', token);
        //     axios.post(
        //         "/RegisterUserBankAccount",
        //         formData
        //         )
        //         .then(res => {
        //         console.log("Account Linked!");
        //         console.log(token);
        //         console.log(metadata);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
        // }
    }
    
    handleOnExit() {
    // handle the case when your user exits Link
        console.log("Onexit ");
    }

    
}

function FullTransactionCard(props){
    return (
        <a style={{textDecoration: "none"}}>
        <br />
        <div  className="betfullyShadow1">
            {/* className="justify-content-around" */}
            <Row  style={{backgroundColor: 'white'}}>
                
                <Col >
                    <Card.Body  >
                        
                        <div className="d-flex justify-content-between ">
                            <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>Processed: {(props.IsProcessed)?"yes":"no"}</h4>
                            <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>Name: {props.PointsAwarded}</h4>
                            <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>${props.AmountProcessed}</h4>
                            <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.Date}</h4>
                            <h4 className="align-center mr-1" style={{color:'black'}}>
                                &#9654;
                            </h4>
                        </div>
                    </Card.Body>
                </Col>
            </Row>
            
        </div>
        </a>
    );
  }

function BetfullyTransactionCard(props){
    return (
        <a style={{textDecoration: "none"}}>
        <br />
        <div  className="betfullyShadow1">
            {/* className="justify-content-around" */}
            <Row  style={{backgroundColor: 'white'}}>
                
                <Col >
                    <Card.Body  >
                        
                        <div className="d-flex justify-content-between ">
                            <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>Client Id: {props.ClientId}</h4>
                            <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>Betfully Points: {props.PointsAwarded}</h4>
                            <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>${props.AmountProcessed}</h4>
                            <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.Date}</h4>
                            <h4 className="align-center mr-1" style={{color:'black'}}>
                                &#9654;
                            </h4>
                        </div>
                    </Card.Body>
                </Col>
            </Row>
            
        </div>
        </a>
    );
  }

function AccountCard(props){
    return (
        <div>
        <br />
        <div  className="betfullyShadow1">
            {/* className="justify-content-around" */}
            <Row  style={{backgroundColor: 'white'}}>
                
                <Col >
                    <Card.Body  >
                        
                        <div className="d-flex justify-content-between ">
                            <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.CardTitle}</h2>
                            <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.Mask}</h2>
                            <h2 className="align-center mr-1" style={{color:'black'}}>
                                &#9654;
                            </h2>
                        </div>
                    </Card.Body>
                </Col>
            </Row>
            
        </div>
</div>
    );
  }

  function RedeemPointsCard(props){
    return (
        <div>
        <br />
        <div  className="betfullyShadow1">
            {/* className="justify-content-around" */}
            <Row  style={{backgroundColor: 'white'}}>
                
                <Col >
                    <Card.Body  >
                        
                        <Card.Title className="text-left ">{props.CardTitle}</Card.Title>
                        <Card.Text className="text-left pl-3">
                        {   props.CardDesc}
                        </Card.Text>
                        <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.PointsCost} Pts</h4>
                        <h4 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>Type: {props.RewardType}</h4>
                    </Card.Body>
                </Col>
            </Row>
            
        </div>
</div>
    );
  }

function OfferCard(props){
    return (
        // <div onClick={true ? ()=>{props.offerClicked(props.OfferId) }:console.log("Disabled")}>
        <div>
        <br />
        <Card  className="cardItem ">
            {/* className="justify-content-around" */}
            <Row  >
                <Col xs={2} className="text-center">
               
                </Col>
                <Col xs={3}  >
                {/* cardImage p-4 */}
                
                    <Card.Img  className="cardImage pt-4 pb-1"   src={"/assets/BetFully_v1-06.png"}/>
                </Col>
                <Col >
                    <Card.Body  >
                        <Card.Title className="text-left ">{props.CardTitle}</Card.Title>
                        <Card.Text className="text-left pl-3">
                        {props.CardDesc}
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Row>
            
        </Card>
</div>
    );
  }

function BankCard(props){
    if(props.PlaidEnabled != null){
        console.log("PlaidEnabled is true!");
        console.log("PlaidEnabled is "+props.PlaidEnabled);
        return(
        <a  style={{textDecoration: "none"}}>
        <div  className="betfullyShadow1">
            {/* className="justify-content-around" */}
            <Row  style={{backgroundColor: 'white'}}>
                
                <Col >
                    <Card.Body  >
                        {/* <Card.Title className="text-left ">{props.bankName}</Card.Title> */}
                        
                        <div className="d-flex justify-content-between ">
                            {props.bankLogo ? <img src={`data:image/png;base64,${props.bankLogo}`}/>: ''}
                            {/* <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.AccountNum}</h2> */}
                            <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}} href={""}>Plaid Enabled</h2>
                       
                            <h2 className="align-center mr-1" style={{color:'black'}}>
                                &#9654;
                            </h2>
                            <PlaidLink
                                clientName="Betfully"
                                env={plaidConst.ENVIRONMENT}
                                product={["auth", "transactions"]}
                                publicKey={plaidConst.PUBLIC_KEY}
                                onExit={props.handleOnExit}
                                onSuccess={props.handleOnSuccess}
                                token={props.PlaidEnabled}
                                variant="primary" className="w-50 plaidLinkButton font-weight-bold">
                                Link Account Now
                            </PlaidLink>
                        </div>
                    </Card.Body>
                </Col>
            </Row>
            
        </div>
        </a>);
    }
    else if(props.RefreshRequired){
        return (
            <a href="#" onClick={props.buttonOn ? ()=>{console.log("Enabled"); props.onEnabled(false); props.onRefresh(props.AccountNum); }:console.log("Disabled")} style={{textDecoration: "none"}}>
            <div  className="betfullyShadow1">
                {/* className="justify-content-around" */}
                <Row  style={{backgroundColor: 'white'}}>
                    
                    <Col >
                        <Card.Body  >
                        {/* <Card.Title className="text-left ">{props.bankName}</Card.Title> */}
                        
                            <div className="d-flex justify-content-between ">
                                {props.bankLogo ? <img src={`data:image/png;base64,${props.bankLogo}`}/>: ''}
                                {/* <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.AccountNum}</h2> */}
                                <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}} href={""}>Login Required</h2>
                                <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.Status}</h2>
                                <h2 className="align-center mr-1" style={{color:'black'}}>
                                    &#9654;
                                </h2>
                            </div>
                        </Card.Body>
                    </Col>
                </Row>
                
            </div>
            </a>
        );
    }else{
        return (
            <a >
            <br />
            <div  className="betfullyShadow1">
                {/* className="justify-content-around" */}
                
                <Row  style={{backgroundColor: 'white'}}>
                    <Col >
                        <Card.Body  >
                        {/* <Card.Title className="text-left ">{props.bankName}</Card.Title> */}
                        
                            <div className="d-flex justify-content-between ">
                            {props.bankLogo ? <img src={`data:image/png;base64,${props.bankLogo}`}/>: ''}
                                {/* <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.AccountNum}</h2> */}
                                <h2 className="pl-4 mb-0 font-weight-bold" style={{color:'black'}}>{props.Status}</h2>
                                <h2 className="align-center mr-1" style={{color:'black'}}>
                                    &#9654;
                                </h2>
                            </div>
                        </Card.Body>
                    </Col>
                </Row>
                <div className="text-center" onClick={()=>props.deleteBank(props.AccountNum) }>Delete</div>
            </div>
            </a>
        );
    }
   
  }

  function MapBankAccounts(props){
  
    console.log("RENDER");
    var obj = props.BankAccounts;
    if(obj != null){
        const listItems =  obj.map(
        
        (number) => 
            <CardSection key={number.Id} Mask={number.Mask} Name={number.Name} bankAccountId={number.Id} cardType={cardTypes.BANK_ACCOUNT} />
        
        );
    
    return (<div  className="d-flex flex-column" >{listItems}<br /></div>);
  }else{
    return (<div  className="d-flex flex-column" >Loading...<br /></div>);
  }
}

const mapStateToProps = state => {
    return {
      currFirebaseToken: state.currFirebaseToken,
      currUser: state.currUser
    };
}
  
const mapDispatchToProps = dispatch => {
    return {
        onIngredientsAdded: (theToken) => dispatch({type: actionTypes.SAVE_TOKEN, firebaseToken: theToken}),
        onUserRetrieved: (theUser) => dispatch({type: actionTypes.SAVE_USER, user: theUser}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardSection);